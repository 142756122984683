.$(prefix)-Body {
  .st-HeaderContainer {
    position: fixed;
    width: 100%;
    /* height: 100%; */
    top: 0;
    z-index: 100;
    overflow: auto;

    + * {
      padding-top: 160px;
      @media (--only-sm-screen) { padding-top: 80px; }
    }

    &.js-InView {
      opacity: 0.0;
      .dbg & { opacity: 1.0; }
    }
  }

  .st-Header_Outer-active {
    height: 100%;
    /* overflow: hidden; */
    .st-HeaderContainer_Inner {
      min-height: 640px;
      @media (--only-md-screen) { min-height: 720px; }
      @media (--only-sm-screen) { min-height: 0; }
    }
  }

    .st-HeaderContainer_Inner {
      height: 100%;
      overflow: hidden;
    }

      /* ============================================================================= */
      /*  .st-Header                                                                   */
      /* ============================================================================= */
      .st-Header {
        background-color: $backgroundColor0;
      }
        .st-Header_Inner {
          padding: 80px 0;
          position: relative;
          z-index: 2;
          @media (--only-sm-screen) { padding: 40px 0; }
        }
          .st-HeaderHeading {
            width: 100%;
            padding: 0;
            margin: 0;
            position: absolute;
            top: 50%;
            left: 0%;
            z-index: 1;
            transform: translate(0%, -50%);
          }
            $headingLinkPadding: 14;
            .st-HeaderHeading_Inner {
              width: 200px;
              @media (--only-sm-screen) { width: 128px; }
            }
              .st-HeaderHeading_Link {
                display: block;
                position: relative;
                &:after {
                  content: '';
                  display: block;
                  box-sizing: content-box;
                  width: 100%;
                  height: 100%;
                  padding: 14px;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                }
                &:hover {
                  .st-HeaderSVG_El { fill: $typographyColor2; }
                }
              }
                .st-HeaderHeadingSVG_Container {
                  &:before {
                    padding-top: calc(15 / 200 * 100%);
                  }
                }
                  .st-HeaderSVG {
                    position: absolute;
                    top: 0;
                    left: 0;
                  }
                    .st-HeaderSVG_El {
                      fill: $typographyColor0;
                      transition-property: fill;
                      transition-duration: 0.2s;
                      transition-timing-function: cubic-bezier(0.14, 1, 0.54, 0.92);
                    }
          /* End .st-HeaderHeading */

          .st-MenuHeading {
            $fontSize: 16;
            $lineHeight: 24;
            font-family: "Montserrat", $fontFamilySet1;
            font-size: calc($fontSize / $remBase)rem;
            font-weight: 400;
            text-transform: uppercase;
            letter-spacing: 0.16em;
            line-height: calc($lineHeight / $fontSize);
            padding: 0;
            margin: 0;
            position: absolute;
            top: 50%;
            right: -0.14em;
            z-index: 2;
            transform: translate(0%, -50%);
            @media (--only-sm-screen) {
              $fontSize: 24;
              font-size: calc($fontSize / 2 / $remBase)rem;
            }
          }
            .st-MenuHeading_Link {
              cursor: pointer;
              &:after {
                content: '';
                display: block;
                box-sizing: content-box;
                width: 100%;
                height: 100%;
                padding: 14px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }
            }
      /* ============================================================================= */
      /*  End .st-Header                                                               */
      /* ============================================================================= */


      /* ============================================================================= */
      /*  .st-Menu                                                                     */
      /* ============================================================================= */
      .st-Menu {
        background-color: $backgroundColor0;
        display: none;
        width: 100%;
        height: 100%;
        min-height: 780px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        opacity: 0.0;
        @media (--only-md-screen) { min-height: 940px; }
        @media (--only-sm-screen) { min-height: auto; }
        .sw_Debug & {
          display: block;
          opacity: 1.0;
        }

        .sw-Container_Inner { height: 100%; }
      }
        .st-Menu_Inner {
          height: 100%;
          position: relative;
          @media (--only-sm-screen) { padding: 116px 0 30px; }
        }

          .st-MenuNav {
            width: 100%;
            position: absolute;
            top: 50%;
            left: 0%;
            transform: translate(0%, -50%);
            @media (--only-md-screen) { padding: 0 0 40px; }
            @media (--only-sm-screen) {
              margin: 0 0 52px;
              position: relative;
              top: 0%;
              left: 0%;
              transform: translate(0%, 0%);
            }
          }
            .st-MenuList {
              list-style: none;
              padding: 0;
              margin: 0;
            }
              .st-MenuList_Item {
                text-align: center;
                opacity: 0.0;
                .sw_Debug & { opacity: 1.0; }
              }
                .st-MenuList_Link {
                  $fontSize: 44;
                  $lineHeight: 80.5;
                  font-family: "Montserrat", $fontFamilySet1;
                  font-size: calc($fontSize / $remBase)rem;
                  font-weight: 500;
                  text-transform: uppercase;
                  letter-spacing: 0.04em;
                  line-height: calc($lineHeight / $fontSize);
                  @media (--only-sm-screen) {
                    $fontSize: 44;
                    $lineHeight: 100;
                    font-size: calc($fontSize / 2 / $remBase)rem;
                    line-height: calc($lineHeight / $fontSize);
                  }
                }
          /* End .st-MenuNav */

          .st-MenuLanguage {
            width: 106px;
            position: absolute;
            top: 50%;
            right: 0%;
            opacity: 0.0;
            .sw_Debug & { opacity: 1.0; }
            @media (--only-md-screen-less) { width: 100%; }
            @media (--only-md-screen) {
              padding: 0 0 160px;
              margin: 0 auto;
              top: auto;
              right: auto;
              bottom: 0;
            }
            @media (--only-sm-screen) {
              margin: 0 auto 60px;
              position: relative;
              top: 0;
              right: 0;
            }
          }
            .st-MenuLanguage_Inner {
              position: relative;
              &:before {
                content: '';
                background-color: $backgroundColor3;
                display: block;
                width: 1px;
                height: 74px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(0%, -50%) rotate(34deg);
                @media (--only-sm-screen) { height: calc(100% + 10px); }
              }
            }
              .st-MenuLanguageList {
                display: flex;
                list-style: none;
                width: 100%;
                padding: 0;
                margin: 0;
                @media (--only-md-screen-over) { justify-content: space-between; }
                @media (--only-md-screen-less) { justify-content: center; }
              }

                $xlgListItemWidth: 32;
                $xlgListLinkGutterWidth: 4;

                $mdListItemWidth: 60;
                $mdListLinkGutterWidth: 4;
                $mdListItemInnerWidth: 32;

                $smListItemWidth: 60;
                $smListLinkGutterWidth: 8;
                $smListItemInnerWidth: 38;

                .st-MenuLanguageItem {
                  display: block;
                  width: $(xlgListItemWidth)px;

                  @media (--only-md-screen) { width: $(mdListItemWidth)px; }
                  @media (--only-sm-screen) { width: calc($smListItemWidth / $smallScreenInnerWidth * 100%); }
                }
                .st-MenuLanguageItem-ja {
                  .ims-Lang_ja & {
                    .st-MenuLanguageSVG_Path { fill: $typographyColor2; }
                  }
                }
                .st-MenuLanguageItem-en {
                  .ims-Lang_en & {
                    .st-MenuLanguageSVG_Path { fill: $typographyColor2; }
                  }
                }
                  .st-MenuLanguageItem_Inner {}
                    .st-MenuLanguageList_Link {
                      display: block;
                      padding: $(xlgListLinkGutterWidth)px;
                      margin: 0 auto;

                      &:hover {
                        .st-MenuLanguageSVG_Path { fill: $typographyColor2; }
                      }

                      @media (--only-md-screen) {
                        width: $(mdListItemInnerWidth)px;
                        padding: $(mdListLinkGutterWidth)px;
                      }

                      @media (--only-sm-screen) {
                        width: calc($smListItemInnerWidth / $smListItemWidth * 100%);
                        padding: calc($smListLinkGutterWidth / $smListItemWidth * 100%);
                      }
                    }
                      .st-MenuLanguageSVG_Container {
                        &:before { padding-top: calc(22 / 22 * 100%); }
                      }
                        /*.st-MenuLanguageSVG {}*/
                          .st-MenuLanguageSVG_Path {
                            fill: $typographyColor0;
                            transition-property: fill;
                            transition-duration: 0.2s;
                            transition-timing-function: cubic-bezier(0.14, 1, 0.54, 0.92);
                          }
          /* End .st-MenuLanguage */

          .st-HeaderSns {
            width: 100%;
            padding: 0 0 80px;
            position: absolute;
            bottom: 0;
            opacity: 0.0;
            .sw_Debug & { opacity: 1.0; }
            @media (--only-sm-screen) {
              padding: 0;
              position: relative;
            }
          }
            .st-HeaderSns_Inner {
              @media (--only-sm-screen) {
                max-width: 240px;
                margin: 0 auto;
              }
            }
              .st-HeaderSnsList {
                display: flex;
                align-content: center;
                align-items: center;
                list-style: none;
                padding: 0;
                margin: 0;
                @media (--only-md-screen-over) { justify-content: center; }
                @media (--only-md-screen-less) { justify-content: space-between; }
              }
                .st-HeaderSnsItem {
                  text-align: center;
                  @media (--only-sm-screen) { width: calc(42 / $smallScreenInnerWidth * 100%); }
                }
                  .st-HeaderSnsItem_Inner {
                    padding: 0 17px;
                    @media (--only-sm-screen) { padding: 0; }
                  }
                    .st-HeaderSns_Link {
                      $fontSize: 25;
                      display: block;
                      font-size: calc($fontSize / $remBase)rem;
                      line-height: 1;
                      @media (--only-sm-screen) { padding: calc(6 / 42 * 100%) 0; }
                    }
            /* .st-HeaderSns */
    /* ============================================================================= */
    /*  End .st-Menu                                                                 */
    /* ============================================================================= */
}
