.$(prefix)-Body {
  .st-Lording {
    background-color: $backgroundColor0;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2147483647;
    .no-js & { display: none; }
    .dbg & { display: none; }
  }
    .st-LordingImgContainer {
      width: 80px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      @media (--only-sm-screen) {
        width: 40px;
      }
    }
      .st-LordingImg {
        /* opacity: 0.0; */
        /* .dbg & { opacity: 1.0; } */
      }
}
