.$(prefix)-Body {
  .st-Footer_Outer {
    background-color: $backgroundColor1;
    &.js-InView {
      opacity: 0.0;
      .dbg & { opacity: 1.0; }
    }
  }
    .st-Footer {
      padding: 80px 0;

      @media (--only-sm-screen) {
        padding: 40px 0;
      }
    }
      .st-Footer_Inner {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        justify-content: space-between;

        @media (--only-sm-screen-less) {
          display: block;
        }
      }

        /* .st-FooterGroup-0 {
          display: flex;
          justify-content: space-between;
          margin: 0 0 60px;
          @media (--only-md-screen-less) { display: block; }
          @media (--only-sm-screen) { margin: 0 0 32px; }
        } */

            /* .st-FooterSns {} */
              .st-FooterSnsList {
                display: flex;
                color: $typographyColor3;
                list-style: none;
                padding: 0;
                margin: 0;

                @media (--only-sm-screen-less) {
                  justify-content: center;
                  margin: 0 0 60px;
                }
              }
                .st-FooterSnsItem {
                  & + .st-FooterSnsItem {
                    .st-FooterSnsItem_Inner {
                      padding: 0 0 0 30px;
                      @media (--only-md-screen-less) { padding: 0 0 0 40px; }
                    }
                  }
                }
                  .st-FooterSns_Link {
                    $fontSize: 24;

                    display: block;
                    font-size: $(fontSize)px;
                    line-height: 1;
                    position: relative;

                    &::after {
                      content: '';
                      display: block;
                      box-sizing: content-box;
                      width: 100%;
                      height: 100%;
                      padding: 10px;
                      position: absolute;
                      top: 50%;
                      left: 50%;
                      transform: translate(-50%, -50%);
                    }
                  }

            .st-FooterCopyright {
              $fontSize: 10;
              $lineHeight: 15;

              color: #999;
              font-size: $(fontSize)px;
              font-family: 'Montserrat', $fontFamilySet3;
              letter-spacing: 0.04em;
              line-height: 1.4;

              @media (--only-sm-screen-less) {
                text-align: center;
              }
            }
}
