@font-face {
  font-family: 'ImsIcons';
  src: url('../../fonts/ims-icons.eot');
  src: url('../../fonts/ims-icons.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/ims-icons.woff') format('woff'),
    url('../../fonts/ims-icons.ttf') format('truetype'),
    url('../../fonts/ims-icons.svg#ims-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

/*::-moz-selection { color: $typographyColor3; background: $typographyColor0; }
::selection { color: $typographyColor3; background: $typographyColor0; }*/

.$(prefix) {

  /* min-height: 100%; */
}

.$(prefix)-Body {
  color: $typographyColor0;
  /* min-height: 100%; */

  [data-icon] {
    &:before {
    font-family: 'ImsIcons';
    content: attr(data-icon);
    speak: none;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    }
  }

  .sw-Body-white {
    background-color: $backgroundColor0;
  }

  .sw-SvgContainer {
    display: block;
    position: relative;
    &:before {
      content: '';
      display: block;
      width: 100%;
      position: relative;
    }
    > svg {
      max-width: 100%;
      width: 100%;
      max-height: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .sw-Svg-fluid {
    max-width: 100%;
    width: 100%;
    max-height: 100%;
    height: 100%;
  }

  .sw-Image-w_fluid,
  .sw-Image-fluid {
    max-width: 100%;
    width: 100%;
    height: auto;
    line-height: 0;
  }

  .sw-Image-h_fluid,
  .l-Image-h_fluid {
    width: auto;
    max-height: 100%;
    height: 100%;
    line-height: 0;
  }

  /* ============================================================================= */
  /*  Typography                                                                   */
  /* ============================================================================= */
  .sw-HeadingTypography-xLarge {
    $fontSize: 32;
    $lineHeight: 42;
    font-family: "Montserrat", $fontFamilySet1;
    font-feature-settings: 'palt' 1;
    font-size: calc($fontSize / $remBase)rem;
    font-kerning: auto;
    font-weight: 400;
    letter-spacing: 0.04em;
    line-height: calc($lineHeight / $fontSize);
    padding: 0;
    margin-top: calc(-1 * ($lineHeight - $fontSize) / $fontSize / 2)em;
    @media (--only-sm-screen) {
      $fontSize: 36;
      $lineHeight: 54;
      font-size: calc($fontSize / 2 / $remBase)rem;
      line-height: calc($lineHeight / 2 / $remBase)rem;
      margin-top: calc(-1 * ($lineHeight - $fontSize) / $fontSize / 2)em;
    }
  }

  .sw-HeadingTypography-middle {
    $fontSize: 20;
    $lineHeight: 30;
    font-family: "Montserrat", $fontFamilySet1;
    font-feature-settings: "palt" 1;
    font-size: calc($fontSize / $remBase)rem;
    font-kerning: auto;
    font-weight: 400;
    letter-spacing: 0.04em;
    line-height: calc($lineHeight / $fontSize);
    padding: 0;
    margin-top: calc(-1 * ($lineHeight - $fontSize) / $fontSize / 2)em;
    @media (--only-sm-screen) {
      $fontSize: 30;
      $lineHeight: 45;
      font-size: calc($fontSize / 2 / $remBase)rem;
      line-height: calc($lineHeight / 2 / $remBase)rem;
    }
  }

  .sw-SentenceTypography-middle {
    $fontSize: 14;
    $lineHeight: 20;
    font-family: $fontFamilySet1;
    font-feature-settings: "palt" 1;
    font-size: calc($fontSize / $remBase)rem;
    font-kerning: auto;
    font-weight: 400;
    letter-spacing: 0.04em;
    line-height: calc($lineHeight / $fontSize);
    margin-top: calc(-1 * ($lineHeight - $fontSize) / $fontSize / 2)em;
    @media (--only-sm-screen) {
      $fontSize: 24;
      $lineHeight: 40;
      font-size: calc($fontSize / 2 / $remBase)rem;
      line-height: calc($lineHeight / 2 / $remBase)rem;
    }
  }
  /* ============================================================================= */
  /*  End Typography                                                               */
  /* ============================================================================= */

  .sw-InlineLink-effect-black {
    color: $typographyColor0;
    text-decoration: none;
    transition-property: color;
    transition-duration: 0.2s;
    transition-timing-function: cubic-bezier(0.14, 1, 0.54, 0.92);
    &:hover {
      color: $typographyColor2;
    }
  }

  .sw-InlineLink-effect-white {
    color: $typographyColor3;
    text-decoration: none;
    transition-property: color;
    transition-duration: 0.2s;
    transition-timing-function: cubic-bezier(0.14, 1, 0.54, 0.92);
    &:hover {
      color: $typographyColor2;
    }
  }

  /* ============================================================================= */
  /*  .sw-Container                                                                */
  /* ============================================================================= */
  .sw-Container {
    width: 100%;

    /*@media (--only-xlg-screen) { background-color: yellow; }*/
    /*@media (--only-lg-screen) { background-color: blue; }*/
    /*@media (--only-md-screen) { background-color: green; }*/
    /*@media (--only-sm-screen) { background-color: red; }*/
  }

    .sw-Container_Inner {
      width: 100%;
      /* max-width: 1200px; */
      padding-right: 100px;
      padding-left: 100px;
      margin: 0 auto;
      @media (--only-md-screen) {
        padding-right: calc($mediumScreenGutterWidth / $mediumScreenWidth * 100%);
        padding-left: calc($mediumScreenGutterWidth / $mediumScreenWidth * 100%);
      }
      @media (--only-sm-screen) {
        padding-right: $(smallScreenGutterWidth)px;
        padding-left: $(smallScreenGutterWidth)px;
      }
    }

      .sw-Container_DeepInner {
        max-width: 1000px;
        padding: 0 100px;
        margin: 0 auto;
      }
  /* ============================================================================= */
  /*  End .sw-Container                                                            */
  /* ============================================================================= */



  /* ============================================================================= */
  /*  .sw-Button                                                                   */
  /* ============================================================================= */
  .sw-Button {
    cursor: pointer;
    display: block;
    font-family: "Montserrat", $fontFamilySet1;
    font-weight: 400;
    text-align: center;
    text-decoration: none;
    touch-action: manipulation;
    white-space: nowrap;
    user-select: none;
    width: 100%;
    margin: 0 auto;
    transition-property: background-color, color;
    transition-duration: 0.2s;
    transition-timing-function: cubic-bezier(0.14, 1, 0.54, 0.92);
  }

  .sw-Button-transparent-white {
    color: $typographyColor3;
    border: solid 1px $borderColor2;
    background-color: transparent;
    &:not(:disabled):hover {
      border: solid 1px $borderColor1;
      background-color: $backgroundColor2;
    }
  }

  .sw-Button-black {
    color: $typographyColor3;
    border: solid 1px $borderColor1;
    background-color: $backgroundColor1;
    &:not(:disabled):hover {
      border: solid 1px $borderColor0;
      background-color: $backgroundColor3;
    }
  }

  .sw-Button-medium {
    $h: 40;
    $borderWidth: 1 * 2;
    $fontSize: 14;
    $lineHeight: 21;
    font-feature-settings: "palt" 1;
    font-size: calc($fontSize / $remBase)rem;
    font-kerning: auto;
    letter-spacing: 0.04em;
    line-height: calc($lineHeight / $fontSize);
    padding: calc(($h - $lineHeight - $borderWidth) / 2)px 0;
  }

  .sw-Button-large {
    $h: 80;
    $borderWidth: 1 * 2;
    $fontSize: 20;
    $lineHeight: 30;
    font-feature-settings: "palt" 1;
    font-size: calc($fontSize / $remBase)rem;
    font-kerning: auto;
    letter-spacing: 0.04em;
    line-height: calc($lineHeight / $fontSize);
    padding: calc(($h - $lineHeight - $borderWidth) / 2)px 0;
    @media (--only-sm-screen) {
      $h: 80;
      $fontSize: 30;
      $lineHeight: 45;
      font-size: calc($fontSize / 2 / $remBase)rem;
      line-height: calc($lineHeight / 2 / $remBase)rem;
      padding: calc((($h / 2) - ($lineHeight / 2) - $borderWidth) / 2)px 0;
    }
  }
  /* ============================================================================= */
  /*  .sw-Button                                                                   */
  /* ============================================================================= */


  /* ============================================================================= */
  /*  .sw-Select                                                                   */
  /* ============================================================================= */
  .sw-SelectOuter {
    border: 1px solid $borderColor0;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition-property: border;
    transition-duration: 0.4s;
    transition-timing-function: cubic-bezier(0.14, 1, 0.54, 0.92);
    &:hover {
      .sw-SelectSVG_Arrow {
        fill: $typographyColor3;
      }

      .sw-Select {
        background-color: $backgroundColor3;
        color: $typographyColor3;
      }
    }
  }

  /*.sw-Select-size_middle {
    .sw-Select {
      $fontSize: 14;
      $lineHeight: 24;
      font-size: calc($fontSize / $remBase)rem;
      letter-spacing: 0.04em;
      line-height: calc($lineHeight / $fontSize);
      padding: calc(7 / $remBase)rem 0;
    }
  }*/

  .sw-Select-color_white {
    border: 1px solid $borderColor0;
    &:hover {
      .sw-SelectSVG_Arrow {
        fill: $typographyColor3;
      }

      .sw-Select {
        background-color: $backgroundColor3;
        color: $typographyColor3;
      }
    }

      .sw-SelectSVG_Arrow { fill: $typographyColor0; }

    .sw-Select {
      background-color: $backgroundColor0;
      color: $borderColor1;
      option, optgroup {
        background-color: $backgroundColor0;
        color: $borderColor1;
      }
    }
  }

    .sw-SelectSVG {
      position: absolute;
      top: 50%;
      right: calc(15 / $remBase)rem;
      z-index: 2;
      transform: translate(0, -50%);
      @media (--only-sm-screen) {
        right: calc(9 / $remBase)rem;
      }
    }
      .sw-SelectSVG_Arrow {
        fill: $typographyColor0;
        transition-property: fill;
        transition-duration: 0.2s;
        transition-timing-function: cubic-bezier(0.14, 1, 0.54, 0.92);
      }

    .sw-Select {
      cursor: pointer;
      border-radius: 0;
      display: block;
      font-family: "Montserrat", $fontFamilySet1;
      appearance: none;
      box-sizing: border-box;
      width: 126%;
      padding: 0;
      outline: none;
      position: relative;
      z-index: 1;
      transition-property: background-color, color;
      transition-duration: 0.2s;
      transition-timing-function: cubic-bezier(0.14, 1, 0.54, 0.92);
      option, optgroup { all: initial }
    }

    .sw-Select-size_middle {
      $fontSize: 14;
      $lineHeight: 38;
      font-size: calc($fontSize / $remBase)rem;

      letter-spacing: 0.04em;
      line-height: calc($lineHeight / $fontSize);
      padding: 0 1.0em;
      height: calc($lineHeight / $fontSize)em;
      @media (--only-sm-screen) {
        $fontSize: 11;
        $lineHeight: 28;
        font-size: calc($fontSize / $remBase)rem;
        text-indent: calc(9 / $remBase)rem;
        line-height: calc($lineHeight / $remBase)rem;
      }
    }
  /* ============================================================================= */
  /*  End .sw-Select                                                               */
  /* ============================================================================= */



  /* ============================================================================= */
  /*  .sw-Link                                                                     */
  /* ============================================================================= */
  .sw-Link_Inline-black {
    color: $typographyColor0;
    text-decoration: none;
    border-bottom-color: $borderColor1;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    padding: 0 0 2px;
    transition-property: border-bottom-color, color;
    transition-duration: 0.2s;
    transition-timing-function: cubic-bezier(0.14, 1, 0.54, 0.92);
    &:hover {
      color: $typographyColor2;
      border-bottom-color: $borderColor0;
    }
  }
  /* ============================================================================= */
  /*  End .sw-Link                                                                 */
  /* ============================================================================= */



  /* ============================================================================= */
  /*  .sw-SR-only                                                                  */
  /* ============================================================================= */
  .sw-SR-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
  }
  /* ============================================================================= */
  /*  End .sw-SR-only                                                              */
  /* ============================================================================= */
}
